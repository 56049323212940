.divSizeImage {
	width: auto;
	height: auto;
	max-height: 100%;
	max-width: 100%;
}

input.pwd {
	-webkit-text-security: disc;
}

input[type='file'] {
	display: none;
}

.custom-file-upload {
	display: inline-block;
	padding: 6px 12px;
	cursor: pointer;
}

.custom-doc-upload {
	display: inline-block;
	padding: 0px 10px;
	cursor: pointer;
}

.dropzone {
	text-align: center;
	padding: 20px;
	border: 3px dashed #eeeeee;
	background-color: #fafafa;
	color: #bdbdbd;
	margin-bottom: 10px;
}

.accept {
	border-color: #107c10 !important;
}

.reject {
	border-color: #d83b01 !important;
}

.thumbsContainer {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin-top: 16px;
}

.thumb {
	display: inline-flex;
	border-radius: 2px;
	border: 1px solid #eaeaea;
	margin-bottom: 8px;
	margin-right: 8px;
	width: 100px;
	height: 100px;
	padding: 4px;
	box-sizing: border-box;
}

.thumbInner {
	position: relative;
	display: flex;
	min-width: 0px;
	overflow: hidden;
}

.previewImg {
	display: block;
	width: 100px;
	height: 100px;
}

.btnRemove {
	position: absolute;
	top: 2px;
	right: 2px;
}

.verify-input {
	height: 72px !important;
	font-size: 32px;
	font-weight: 700;
	color: #141515;
	letter-spacing: 5px;
	padding: 0 0 0 5px !important;
	max-width: 190px;
	margin: auto;
	text-align: center !important;
}
